<template>
  <div>
    <div v-if="userPassword">
      <!-- progress -->
      <v-progress-linear
        v-if="
          passwordStrengthScoreData.progress.color &&
          passwordStrengthScoreData.progress.value
        "
        :color="passwordStrengthScoreData.progress.color"
        :value="passwordStrengthScoreData.progress.value"
      />

      <!-- alerta -->
      <v-alert
        v-if="
          passwordStrengthScoreData.alert.type &&
          passwordStrengthScoreData.alert.text
        "
        :type="passwordStrengthScoreData.alert.type"
        outlined
        class="mt-5"
      >
        {{ passwordStrengthScoreData.alert.text }}
      </v-alert>
    </div>
  </div>
</template>

<script>
import zxcvbn from "zxcvbn";

export default {
  name: "ValidateStrengthPassword",
  props: {
    userPassword: String,
  },
  created() {},
  data() {
    return {
      passwordStrengthScore: "",
      passwordStrengthScoreData: {
        alert: {
          type: "",
          text: "",
        },
        progress: {
          color: "",
          value: "",
        },
      },
      passwordStrengthScoreEnum: {
        default: 0,
        weak: 1,
        medium: 2,
        strong: 3,
        tooStrong: 4,
      },
    };
  },
  methods: {
    changePassword(password) {
      // retorna um enum de 0 a 4 => passwordStrengthScoreEnum
      const { score } = zxcvbn(password);
      this.passwordStrengthScore = score;

      // retorno para quando o enum for 0 (default) ou 1 (weak)
      let defaultObject = {
        alert: {
          type: "error",
          text: this.$t("weark_password_description"),
        },
        progress: {
          color: "#F44336",
          value: 25,
        },
      };

      // se for 0 ja retorna o objeto padrão
      if (score === this.passwordStrengthScoreEnum.default) {
        this.passwordStrengthScoreData = defaultObject;
        return;
      }

      const literal = {
        [this.passwordStrengthScoreEnum.weak]: defaultObject,
        [this.passwordStrengthScoreEnum.medium]: {
          alert: {
            type: "warning",
            text: this.$t("medium_password_description"),
          },
          progress: {
            color: "#FFEB3B",
            value: 50,
          },
        },
        [this.passwordStrengthScoreEnum.strong]: {
          alert: {
            type: "info",
            text: this.$t("strong_password_description"),
          },
          progress: {
            color: "#2196F3",
            value: 75,
          },
        },
        [this.passwordStrengthScoreEnum.tooStrong]: {
          alert: {
            type: "success",
            text: this.$t("very_strong_password_description"),
          },
          progress: {
            color: "#4CAF50",
            value: 100,
          },
        },
      };

      // populando o objeto do alerta com o step do score
      this.passwordStrengthScoreData = literal[score];
    },
  },
  watch: {
    userPassword() {
      if (this.userPassword) {
        try {
          this.changePassword(this.userPassword);
        } catch (err) {}
      }
    },
  },
};
</script>

<style scoped>
.mt-5 {
  margin-top: 20px;
}
</style>
