function checkPasswordNumber(password) {
  return /[0-9]/.test(password);
}

function checkPasswordLower(password) {
  return /[a-z]/.test(password);
}

function checkPasswordUpper(password) {
  return /[A-Z]/.test(password);
}

function checkPasswordSpecialChar(password) {
  return /[*&$!@#?]/.test(password);
}

// valida que a senha vai ter no mínimo 6 caracteres distintos.
// valida todos os caracteres, menos espaços em branco.
export function checkPasswordMinCharacter(password, minCharacter) {
  // caso queira passar a quantidade minima de caracteres na chamada do helper. Se não passar, pega o default que é 6.
  const minValue = minCharacter ? minCharacter : 6;

  // remove os espaços
  const cleanPassword = password.replace(/\s/g, "");

  // cria um objeto, que só contém os caracteres que não foram repetidos.
  const unique = new Set(cleanPassword);

  // se a senha tiver 6 ou mais caracteres diferentes o helper retorna true
  return unique.size >= minValue;
}

// valida a senha, se cair em algum if volta a mensagem de erro, se voltar true a senha é válida.
export function verifyRequirements(password) {
  if (!password >= 8) {
    return "at_least_eight_characters";
  }

  if (!checkPasswordNumber(password)) {
    return "at_least_a_number";
  }

  if (!checkPasswordLower(password)) {
    return "at_least_lower";
  }

  if (!checkPasswordUpper(password)) {
    return "at-least_upper";
  }

  if (!checkPasswordSpecialChar(password)) {
    return "password_rule_4";
  }

  if (!checkPasswordMinCharacter(password)) {
    return "password_min_character";
  }

  return true;
}
